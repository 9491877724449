<template>
  <div class="mt-4">
    <div>
      <v-tabs v-model="tabs">
        <v-tab href="#ativos">Ativos</v-tab>
        <v-tab href="#naoativos">Não ativos</v-tab>
        <v-tabs-items v-model="tabs">
          <v-tab-item value="ativos">
            <div class="mt-4">
              <v-data-table :headers="headers" :items="setores" sort-by="descricao" class="border">
                <template v-slot:top>
                  <v-toolbar flat color="white">
                    <v-toolbar-title>Setores Ativos</v-toolbar-title>
                    <v-divider class="mx-4" inset vertical></v-divider>
                    <v-spacer></v-spacer>
                    <v-dialog v-model="dialog" max-width="700px">
                      <template v-slot:activator="{ on }">
                        <v-btn v-if="allowedToAdd" color="primary" dark class="mb-2" v-on="on">Novo setor</v-btn>
                      </template>
                      <v-card>
                        <v-card-title>
                          <span class="headline">{{ formTitle }}</span>
                        </v-card-title>

                        <v-card-text>
                          <v-container>
                            <v-row>
                              <v-col cols="12" sm="4">
                                <v-text-field v-model="editedItem.descricao" label="Descrição"></v-text-field>
                              </v-col>
                              <v-col cols="12" sm="4">
                                <v-text-field type="number" v-model="editedItem.num_vagas"
                                  label="Número de Vagas"></v-text-field>
                              </v-col>
                              <v-col cols="12" sm="4">
                                <v-text-field v-model="editedItem.prefixo" label="Prefixo"></v-text-field>
                              </v-col>
                            </v-row>
                            <v-row>
                              <v-col cols="12" sm="6">
                                <v-select v-model="editedItem.fornecedor" label="Fornecedor" :items="selectFornecedor"
                                  item-text="trade_name" item-value="public_id" @change="selectedFornecedor()"
                                  return-object></v-select>
                              </v-col>
                              <v-col cols="12" sm="6">
                                <v-select v-model="editedItem.terminal" label="Terminal" item-text="descricao"
                                  item-value="id" :items="selectTerminal" return-object></v-select>
                              </v-col>
                            </v-row>
                            <v-row>
                              <v-col cols="12" sm="6">
                                <v-select v-model="editedItem.produto" label="Produto" item-text="descricao"
                                  item-value="id" :items="selectProduto" @change="selectedProduto()"
                                  return-object></v-select>
                              </v-col>
                              <v-col cols="12" sm="6">
                                <v-select v-model="editedItem.subProduto" item-text="descricao" label="Sub Produto"
                                  item-value="id" :items="selectSubProduto" return-object></v-select>
                              </v-col>
                            </v-row>
                            <v-row>
                              <v-col cols="12" sm="12">
                                <v-autocomplete label="Transportadora" v-model="editedItem.transportadora"
                                  prepend-icon="mdi-bag-carry-on" :items="theTransportadoras" item-value="public_id"
                                  :item-text="(item) => item.business_name + ' ' + item.cnpj" />
                              </v-col>
                            </v-row>
                            <v-row>
                              <v-col cols="6" sm="6">
                                <v-select v-model="editedItem.fila" label="Alocação" item-text="text" item-value="value"
                                  :items="options"></v-select>
                              </v-col>
                              <v-col cols="6" sm="6">
                                <v-checkbox v-model="editedItem.bonificada" />
                                <span class="mr-4">Bonificada?</span>
                              </v-col>
                            </v-row>
                          </v-container>
                        </v-card-text>
                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-btn color="blue darken-1" text @click="close">Cancelar</v-btn>
                          <v-btn color="blue darken-1" text @click="save">Salvar</v-btn>
                        </v-card-actions>
                      </v-card>
                    </v-dialog>
                  </v-toolbar>
                </template>
                <template v-slot:[`item.fornecedor`]="{ item }">
                  {{ item.fornecedor ? item.fornecedor.fornecedor_trade_name : 'Nenhum' }}
                </template>
                <template v-for="header in headers.slice(3, 6)" v-slot:[`item.${header.value}`]="{ item }">
                  {{ item[header.value] ? item[header.value].descricao : 'Nenhum' }}
                </template>
                <template v-slot:[`item.fila`]="{ item }">
                  {{ item.fila ? 'Fila' : 'Aleatório' }}
                </template>
                <template v-for="status in headers.slice(7, 10)" v-slot:[`item.${status.value}`]="{ item }">
                  {{ vagas(item.vagas ? item.vagas : [], status.value) }}
                </template>
                <template v-slot:[`item.actions`]="{ item }">
                  <v-icon small class="mr-2" @click="editItem(item)">mdi-pencil</v-icon>
                  <v-icon small @click="goToSetor(item)">mdi-eye</v-icon>
                </template>
                <template v-slot:no-data>
                  <v-btn color="primary" @click="atualizarListaSetores">Reset</v-btn>
                </template>
                <template v-if="!canList">
                  <div>
                    <span class="red--text">Você não tem permissão para visualizar as informações desta
                      tela</span>
                  </div>
                </template>
              </v-data-table>
            </div>
          </v-tab-item>
          <v-tab-item value="naoativos">
            <div class="mt-4">
              <v-data-table :headers="headers" :items="setoresInativos" sort-by="descricao" class="border">
                <template v-slot:top>
                  <v-toolbar flat color="white">
                    <v-toolbar-title>Setores inativos</v-toolbar-title>
                    <v-divider class="mx-4" inset vertical></v-divider>
                    <v-spacer></v-spacer>
                    <v-dialog v-model="dialog" max-width="700px">
                      <template v-slot:activator="{ on }">
                        <v-btn v-if="allowedToAdd" color="primary" dark class="mb-2" v-on="on">Novo setor</v-btn>
                      </template>
                      <v-card>
                        <v-card-title>
                          <span class="headline">{{ formTitle }}</span>
                        </v-card-title>

                        <v-card-text>
                          <v-container>
                            <v-row>
                              <v-col cols="12" sm="4">
                                <v-text-field v-model="editedItem.descricao" label="Descrição"></v-text-field>
                              </v-col>
                              <v-col cols="12" sm="4">
                                <v-text-field type="number" v-model="editedItem.num_vagas"
                                  label="Número de Vagas"></v-text-field>
                              </v-col>
                              <v-col cols="12" sm="4">
                                <v-text-field v-model="editedItem.prefixo" label="Prefixo"></v-text-field>
                              </v-col>
                            </v-row>
                            <v-row>
                              <v-col cols="12" sm="6">
                                <v-select v-model="editedItem.fornecedor" label="Fornecedor" :items="selectFornecedor"
                                  item-text="trade_name" item-value="public_id" @change="selectedFornecedor()"
                                  return-object></v-select>
                              </v-col>
                              <v-col cols="12" sm="6">
                                <v-select v-model="editedItem.terminal" label="Terminal" item-text="descricao"
                                  item-value="id" :items="selectTerminal" return-object></v-select>
                              </v-col>
                            </v-row>
                            <v-row>
                              <v-col cols="12" sm="6">
                                <v-select v-model="editedItem.produto" label="Produto" item-text="descricao"
                                  item-value="id" :items="selectProduto" @change="selectedProduto()"
                                  return-object></v-select>
                              </v-col>
                              <v-col cols="12" sm="6">
                                <v-select v-model="editedItem.subProduto" item-text="descricao" label="Sub Produto"
                                  item-value="id" :items="selectSubProduto" return-object></v-select>
                              </v-col>
                            </v-row>
                            <v-row>
                              <v-col cols="12" sm="12">
                                <v-autocomplete label="Transportadora" v-model="editedItem.transportadora"
                                  prepend-icon="mdi-bag-carry-on" :items="theTransportadoras" item-value="public_id"
                                  :item-text="(item) => item.business_name + ' ' + item.cnpj" />
                              </v-col>
                            </v-row>
                            <v-row>
                              <v-col cols="6" sm="6">
                                <v-select v-model="editedItem.fila" label="Alocação" item-text="text" item-value="value"
                                  :items="options"></v-select>
                              </v-col>
                              <v-col cols="6" sm="6">
                                <v-checkbox v-model="editedItem.bonificada" />
                                <span class="mr-4">Bonificada?</span>
                              </v-col>
                            </v-row>
                          </v-container>
                        </v-card-text>
                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-btn color="blue darken-1" text @click="close">Cancelar</v-btn>
                          <v-btn color="blue darken-1" text @click="save">Salvar</v-btn>
                        </v-card-actions>
                      </v-card>
                    </v-dialog>
                  </v-toolbar>
                </template>
                <template v-slot:[`item.fornecedor`]="{ item }">
                  {{ item.fornecedor ? item.fornecedor.fornecedor_trade_name : 'Nenhum' }}
                </template>
                <template v-for="header in headers.slice(3, 6)" v-slot:[`item.${header.value}`]="{ item }">
                  {{ item[header.value] ? item[header.value].descricao : 'Nenhum' }}
                </template>
                <template v-slot:[`item.fila`]="{ item }">
                  {{ item.fila ? 'Fila' : 'Aleatório' }}
                </template>
                <template v-for="status in headers.slice(7, 10)" v-slot:[`item.${status.value}`]="{ item }">
                  {{ vagas(item.vagas ? item.vagas : [], status.value) }}
                </template>
                <template v-slot:[`item.actions`]="{ item }">
                  <v-icon small class="mr-2" @click="editItem(item)">mdi-pencil</v-icon>
                  <v-icon small @click="goToSetor(item)">mdi-eye</v-icon>
                </template>
                <template v-slot:no-data>
                  <v-btn color="primary" @click="atualizarListaSetores">Reset</v-btn>
                </template>
                <template v-if="!canList">
                  <div>
                    <span class="red--text">Você não tem permissão para visualizar as informações desta
                      tela</span>
                  </div>
                </template>
              </v-data-table>
            </div>
          </v-tab-item>
        </v-tabs-items>
      </v-tabs>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState, mapMutations } from 'vuex'

export default {
  name: 'ListarSetores',
  data() {
    return {
      tabs: null,
      dialog: false,
      editedIndex: -1,
      editedItem: {
        descricao: '',
        fila: false,
        fornecedor: null,
        produto: null,
        subProduto: null,
        terminal: null,
        num_vagas: 0,
        prefixo: '',
        estacionamento: {
          public_id: this.$route.params.estacionamento,
        },
      },
      defaultItem: {
        descricao: '',
        fila: false,
        fornecedor: null,
        produto: null,
        subProduto: null,
        terminal: null,
        num_vagas: 0,
        prefixo: '',
        estacionamento: {
          public_id: this.$route.params.estacionamento,
        },
      },
      headers: [
        {
          text: '#',
          align: 'start',
          sortable: false,
          value: 'id',
        },
        { text: 'Descrição', value: 'descricao' },
        { text: 'Fornecedor', value: 'fornecedor' },
        { text: 'Produto', value: 'produto' },
        { text: 'Sub Produto', value: 'subProduto' },
        { text: 'Terminal', value: 'terminal' },
        { text: 'Alocação', value: 'fila' },
        { text: 'Total de Vagas', value: 'Total' },
        { text: 'Vagas Ocupadas', value: 'Ocupadas' },
        { text: 'Vagas Livres', value: 'Livres' },
        { text: 'Ações', value: 'actions', sortable: false },
      ],
      selectFornecedor: [],
      theTransportadoras: [],
      selectProduto: [],
      selectSubProduto: [],
      selectTerminal: [],
      options: [
        { text: 'Fila', value: true },
        { text: 'Aleatório', value: false },
      ],
    }
  },
  computed: {
    ...mapState('patio', ['setores', 'terminais']),
    ...mapState('subProdutos', ['subProdutos']),
    ...mapState('produtos', ['produtos']),
    ...mapState('fornecedores', ['fornecedores']),
    ...mapState('auth', ['empresaAtual']),

    allowedToAdd() {
      return this.empresaAtual.gate
    },

    formTitle() {
      return this.editedIndex === -1 ? 'Novo Setor' : 'Editar Setor'
    },
  },
  watch: {
    dialog(val) {
      val || this.close()
    },
  },

  async created() {
    if (
      this.$route.params.estacionamento == undefined ||
      this.$route.params.estacionamento == 0
    ) {
      this.$router.push({
        name: 'Estacionamento',
      })
    } else {

      await this.atualizarListaSetores()
      if (this.empresaAtual.gate) {
        const transportadora = await this.getTransportadorasSimple({
          empresa: { public_id: this.empresaAtual.public_id }
        })
        this.theTransportadoras = transportadora

        await this.atualizarListaFornecedores()
        this.selectFornecedor = [
          { trade_name: 'Nenhum', public_id: null },
          ...this.fornecedores,
        ]
      } else {
        this.selectFornecedor = [{ trade_name: 'Nenhum', public_id: null }]
      }
    }
  },

  methods: {
    ...mapActions('patio', ['getSetores', 'addSetor', 'editSetor']),
    ...mapActions('fornecedores', ['getFornecedores']),
    ...mapActions('transportadoras', [
      'getTransportadorasSimple',
    ]),
    ...mapMutations('patio', ['setTerminal']),
    ...mapMutations('produtos', ['setProduto']),
    ...mapMutations('subProdutos', ['setSubProduto']),

    ...mapActions('template', [
      'successMessage',
      'errorMessage',
      'setDialogMessage',
    ]),
    async atualizarListaFornecedores() {
      try {
        await this.getFornecedores()
      } catch (error) {
        if (
          error.response &&
          (error.response.status == 403 || error.response.status == 404)
        ) {
          this.errorMessage('Empresa não é gate para ter fornecedores.')
        } else {
          this.errorMessage(error.response.data)
        }
      }
    },
    async atualizarListaSetores() {
      try {
        await this.getSetores(this.$route.params.estacionamento)
      } catch (error) {
        if (
          error.response &&
          (error.response.status == 403 || error.response.status == 404)
        ) {
          this.errorMessage('Empresa não possui acesso ao estacionamento.')
        } else {
          this.errorMessage(error.response.data)
        }
      }
    },

    goToSetor(item) {
      this.$router.push({
        name: 'Setor',
        params: {
          estacionamento: this.$route.params.estacionamento,
          setor: item.public_id,
        },
      })
    },

    selectedFornecedor() {
      if (this.editedItem.fornecedor != null) {
        if (this.editedItem.fornecedor.public_id != null) {
          this.setProduto(this.editedItem.fornecedor.produtos)
          this.selectProduto = [
            { descricao: 'Nenhum', id: null },
            ...this.produtos,
          ]
          this.setTerminal(this.editedItem.fornecedor.terminais)
          this.selectTerminal = [
            { descricao: 'Nenhum', id: null },
            ...this.terminais,
          ]
        } else {
          this.selectProduto = [{ descricao: 'Nenhum', id: null }]
          this.selectTerminal = [{ descricao: 'Nenhum', id: null }]
          this.editedItem.produto = null
          this.editedItem.subProduto = null
          this.editedItem.terminal = null
        }
      } else {
        this.selectProduto = [{ descricao: 'Nenhum', id: null }]
        this.selectTerminal = [{ descricao: 'Nenhum', id: null }]
        this.editedItem.produto = null
        this.editedItem.subProduto = null
        this.editedItem.terminal = null
      }
      this.selectedProduto()
    },
    selectedProduto() {
      if (this.editedItem.produto != null) {
        if (this.editedItem.produto.id != null) {
          this.setSubProduto(this.editedItem.produto.subprodutos)
          this.selectSubProduto = [
            { descricao: 'Nenhum', id: null },
            ...this.subProdutos,
          ]
        } else {
          this.selectSubProduto = [{ descricao: 'Nenhum', id: null }]
          this.editedItem.subProduto = null
        }
      } else {
        this.selectSubProduto = [{ descricao: 'Nenhum', id: null }]
        this.editedItem.subProduto = null
      }
    },
    fornecedor_trade_name(fornecedor) {
      return fornecedor.fornecedor
        ? fornecedor.fornecedor.trade_name
          ? fornecedor.fornecedor.trade_name
          : 'Nenhum'
        : 'Nenhum'
    },
    vagas(vagas, value) {

      if (value == 'Total') {
        return vagas.filter((vaga) => vaga.ativo).length
      }
      console.log(value)
      if (value == 'Ocupadas') {
        return vagas.filter((vaga) => vaga.ativo && !vaga.livre).length
      }
      if (value == 'Livres') {
        return vagas.filter((vaga) => vaga.ativo && vaga.livre).length
      }
      return 2
    },
    editItem(item) {
      this.editedIndex = this.setores.indexOf(item)
      Object.assign(this.editedItem, item)
      this.editedItem.num_vagas = item.vagas.filter((vaga) => vaga.ativo).length
      this.selectedFornecedor()
      this.selectedProduto()
      this.dialog = true
    },

    close() {
      this.dialog = false
      setTimeout(() => {
        Object.assign(this.editedItem, this.defaultItem)
        this.editedIndex = -1
      }, 300)
    },

    save() {
      if (this.editedIndex > -1) {
        this.editSetor(this.editedItem) // REVER.. Editar
      } else {
        this.addSetor(this.editedItem)
      }
      this.close()
    },
  },
}
</script>
